@keyframes changeColor {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
html,
body {
  background: rgba(13, 88, 255);
  background-color: #3f3f3f;
  margin: 0;
  padding: 0;
}

/* body {
  background: hsla(180, 100%, 50%, 0.1); 
  background-position: center;
  background-size: cover;
}

body::after, body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

body::after {
  background-image: linear-gradient(
    to right,
    hsla(0, 100%, 70%, 0.2),
    hsla(360, 100%, 70%, 0.8)
  );
  background-size: 200% 100%;
  animation: moveGradient1 20s ease-in-out infinite;
}

body::before {
  background-image: linear-gradient(
    to bottom,
    hsla(60, 100%, 70%, 0.3),
    hsla(180, 100%, 70%, 0.7)
  );
  background-size: 250% 100%; 
  animation: moveGradient2 25s ease-in-out infinite;
} */
/* 
@keyframes moveGradient1 {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

@keyframes moveGradient2 {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 50% 0%;
  }
} */

.top-nav {
  width: 100%;
  /* background-color: #000; */
  z-index: 2;
  height: 70px;
  position: fixed;
  display: flex;
}

.glow {
  height: 10px;
  width: 100%;
}

.logo {
  align-items: flex-start;
}

.logo img {
  margin: 20px;
  max-height: 30px;
}

.header-image {
  border-radius: 0 0 0 120px;
  transform: scale(0.8);
  background-size: cover;
  height: 20rem;
  min-height: 900px;
  min-width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left 58% top 45%;
  border-radius: 0 120px 0 120px;
}

.bottom {
  margin-top: 6rem;
  padding-bottom: 10%;
  overflow: hidden;
}

@media screen and (min-width: 700px) {
  .titles {
    /* position: absolute; */
  }

  .container {
    max-width: 60%
  }

  .bottom {
    padding: 0;
  }
}

@media screen and (max-width: 700px) {
  .titles,
  .big-name {
    position: relative;
  }

  .header-image {
    min-height: 500px;
    background-position: left 58% top 45%;
    transform: scale(1.2);
  }

  .check-mark-wrapper {
    display: none;
  }

  .container {
    max-width: 80%
  }

  .bottom {
    padding: 0;
  }

  .header-image {
    transform: scale(1);
    margin: 5rem 0;
  }

}

.container {
  padding: 4rem 5rem;
  width: 100%;
  max-width: none;
  box-sizing: border-box;
}

.hero-wrapper {
  position: fixed;
  top: 10%;
  min-height: 500px;
  min-width: 100%;
  z-index: -1;
}

.hero-wrapper,
.bottom {
  /* background-color: rgb(0, 0, 0); */
  color: #fff;
  font-size: 3em;
  font-weight: 700;
}

.link-p {
  display: block;
  line-height: 1;
  margin: 1.6rem 0;
}

.link,
.links a {
  text-shadow: 0 0 #000;
  text-decoration: underline;
  color: white;
  transition: text-shadow 0.3s ease;
}

.link:hover,
.links a:hover {
  color: white;
  text-shadow: 4px 4px rgb(110, 110, 110);
  text-decoration: none;
}

.back-button {
  display: flex;
  text-shadow: none;
}

.back-button:hover {
  color: #aeaeae;
}

.App {
  text-align: left;
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .spacer {
    height: 5em;
  }
  .mini-spacer {
    height: 0.5em;
  }

  .disappearing-spacer {
    height: 0;
  }
}

@media only screen and (min-width: 700px) {
  .spacer,
  .disappearing-spacer {
    height: 11em;
  }
  .explainer {
    width: 45%;
  }

  .mini-spacer {
    height: 2em;
  }
}

.explainer {
  font-size: 1.3em;
  margin: auto;
}

.background {
  background-size: cover;
  background-repeat: none;
  z-index: -1;
  min-height: 105vh;
}

.block {
  margin: 1em 0 1em 0;
}

.block a,
.block a:hover {
  color: #fff;
}

.home {
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-repeat: none;
  z-index: -1;
  min-height: 100vh;
  /* height: 1500px; */
  /* max-height: 100%; */
}

.psychedelic-background {
  min-height: 100vh;
  height: 100vh;
  max-height: 100%;
}

.cta,
.cta-2 {
  display: inline-block;
  background-color: #f68528;
  padding: 10px;
  border-radius: 5px;
  width: 250px;
  margin: 10px;
}

.cta a,
.cta-2 a {
  text-decoration: none;
  color: white;
}

#navbar {
  color: #000;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.register,
.login {
  padding-left: 30%;
  padding-right: 30%;
}

.register Input,
.login Input {
  margin: 1em;
}

.tiny-spacer {
  padding: 5px;
}

.big-name {
  font-size: 5rem;
  font-weight: 900;
  margin-top: 2rem;
}

.titles h3 {
  font-weight: 200;
}

.footer {
  background-color: #3f3f3f;
}

.work {
  background-color: #e4e4e4;
  color: #0f0f0f;
  background-size: cover;
  background-repeat: none;
  height: 100%;
}

.work h2 {
  font-weight: 700;
}

.work h4 {
  font-weight: 700;
}

.work h6 {
  font-size: 0.75em;
}

.readability h3 {
  font-weight: 800;
}

.readability {
  margin-bottom: 3em;
}

.wrapper {
  background-color: rgba(0, 0, 0, 0.3);
}

.email {
  color: white;
}

.email {
  margin: 25px;
}

.insta,
.insta-logo {
  margin-bottom: 10px;
}

.tiny {
  font-size: 10px;
}

.small {
  font-size: 13px;
}

.gray-boi {
  background-color: rgba(100, 100, 100, 1);
  margin: 2em;
  padding: 1em;
  border-radius: 20px;
}

.subheading {
  font-size: 1.2rem;
  font-weight: 600;
}

.subheader {
  margin-left: 10%;
}

.bold {
  font-weight: 650;
  font-size: 1.8em;
}

.header {
  font-size: 4.5em;
  font-weight: 800;
  margin-top: 1em;
}

@media only screen and (min-width: 700px) {
  .headline {
    font-size: 2.7em;
    font-weight: 800;
    margin-bottom: 3em;
  }

  .header {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 700px) {
  .headline {
    font-size: 1.8em;
    font-weight: 800;
    margin-bottom: 3em;
  }

  .header {
    font-size: 3.5rem;
  }
}

.tiny-spacer {
  padding: 5px;
}

@media only screen and (min-width: 1000px) {
  .large-view {
    visibility: visible;
    margin: 1em;
  }

  .small-view {
    display: none;
  }

  .email {
    width: 25%;
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1000px) {
  .large-view {
    display: none;
  }

  .small-view {
    visibility: visible;
    margin: 1em;
  }

  .email {
    width: 65%;
    font-size: 0.8em;
  }
}

.lost h2 {
  color: black;
}

.lost a,
.other li a {
  color: white;
  text-decoration: underline;
}

.lost a:hover,
.other li a:hover {
  color: white;
  text-decoration: none;
}

.surprise {
  margin-top: 2em;
}

.surprise:hover {
  cursor: pointer;
}

.iframe {
  width: 80vw;
  height: 110vh;
}

.white {
  color: white;
  font-weight: 650;
  text-decoration: underline;
}

.white:hover {
  color: rgb(192, 192, 192);
}

.item {
  margin: 0 5em;
}

.header#work {
  margin: 1em 0 1em 0;
}

.icons {
  margin-top: 1rem;
}

.icons img {
  display: inline-block;
  height: 1.8rem;
  margin: 0 0.6rem;
  transition: filter 0.3s ease;
}
.icons img:hover {
  transition: 0.3s;
  -webkit-filter: drop-shadow(0 0 0.75rem #fff);
  filter: drop-shadow(0 0 0.75rem #fff);
}

.blog-text {
  border-radius: 40px;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}

.blog-text p,
.blog-text li {
  font-size: 1.1rem;
}

.blog-text p,
.blog-text > ul {
  margin: 0px 0 40px 0;
}

.flip-1,
.flip-2,
.flip-3 {
  transform: rotateX(-90deg);
  animation: flip-in 0.5s ease-in forwards;
  animation-delay: 0.5s;
}

.flip-2 {
  animation-delay: 1s;
}

.flip-3 {
  animation-delay: 1.5s;
}

@keyframes flip-in {
  0% {
    transform: rotateX(-90deg);
    transform-style: preserve-3d;
  }
  100% {
    transform: rotateX(0deg);
    transform-style: preserve-3d;
  }
}

.blog-list {
  list-style: disc;
  padding: 0;
}

.blog-list a {
  color: white;
  text-decoration: underline;
  font-size: 1.5rem;
}

.blog-list li {
  margin: 21px 0;
}

.blog-list a:hover {
  color: rgb(221, 221, 221);
}

.code {
  white-space: pre-wrap;
  padding: 2rem;
  background-color: whitesmoke;
  font-family: monospace;
  color: #000000;
  word-wrap: normal;
}

.top {
  display: flex;
  width: 100%;
  min-height: 60vh;
  gap: 2rem;
}

.top-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  order: 1;
}

.top-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  order: 2 !important;
}

.top-right canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 700px) {
  .top {
    flex-direction: column;
  }
  
  .top-right {
    order: -1;
    min-height: 300px;
  }
}
