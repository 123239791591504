.contact {
    position: absolute;
    z-index: 500;
    display: flex;
}

.contact:hover {
    color: #aeaeae;
}

.neumorphism-button {
    color: white;
    margin: 1rem;
    padding: .3rem .5rem;
    min-height: 20px;
    background-color: rgba(1,1,1,0.2);
    border-radius: 5px;
    border: none;
    box-shadow: 4px 4px 10px #6e6e6e, -1px -1px 5px #6e6e6e;
    align-items: flex-end;
    font-weight: 600;
    min-width: 80px;
    height: 40px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.neumorphism-button:hover {
    box-shadow: 5px 5px 10px #6e6e6e, -1px -1px 4px #6e6e6e;
    color: #e6e6e6;
}

.modal-shadow {
    background-color: rgba(1,1,1,0.5);
    height: 100vh;
    width: 100vw;
    animation: fadeIn 0.2s ease-in-out;
}

.modal-outline {
    border: 2px white solid;
    border-radius: 3px;
    background-color: white;
    color: black;
    width: 70%;
    padding: 2rem;
    margin: auto;
    justify-content: space-around;
    position: relative;
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.modal-outline h2 {
    align-items: flex-start;
}
.modal-outline button {
    align-items: flex-end;
}

.modal-outline button {
    position: absolute;
    right: 10px;
    top: 10px;
}
.modal-outline button:after {
    content: '✕';
}

@media screen and (min-width: 700px) {
    .modal-outline {
        width: 50%;
        text-align: left;
        justify-content: start;
    }
}